import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { $ } from 'jquery';
import { take } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { ExcelService } from '../../../services/excel-service.service';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  rawData: any;
  podcasts: PodCast[] = [];
  constructor(private http: HttpClient, private excelService: ExcelService) {
    //this.http.get('assets/PMI_PDU.json').subscribe(data => {
    //  this.rawData = data.rss.channel.item.slice(0, 500);
      
    //  this.rawData.forEach(item => {
    //    if (item.enclosure._url.indexOf(".mp3") > -1) {
    //      let audio = new Audio(item.enclosure._url);
    //      let _this = this;
    //      audio.onloadedmetadata = function () {
    //        let result = _this.podcasts.filter(s => s.url.includes(audio.src));
    //        result[0].duration = Math.floor(audio.duration / 60);
    //      }
    //      //this.getDuration(item.enclosure._url).then(function (audio) {
    //      //  let result = this.podcasts.filter(s => s.url.includes(audio.src));
    //      //  result.duration = audio.duration / 60;
    //      //});
    //      let newPodcast = new PodCast();
    //      newPodcast.url = item.enclosure._url;
    //      newPodcast.title = item.title.replace(/Episode .*: /, '').replace(/ for Pankaj/, '');
    //      var doc = document.createElement("div")
    //      doc.innerHTML = item.description.replace("<p>The Project Management Podcast™ is a trademark of OSP International LLC. All other trademarks mentioned are the property of their respective owners. The Project Management Podcast™ and its RSS feed are copyright © by OSP International LLC 2005 - 2020. All rights reserved. </p>", "");
    //      newPodcast.description = doc.innerText;
    //      doc.innerHTML = item.summary.__text.replace("The Project Management Podcast™ is a trademark of OSP International LLC. All other trademarks mentioned are the property of their respective owners. The Project Management Podcast™ and its RSS feed are copyright © by OSP International LLC 2005 - 2020. All rights reserved.", '');
    //      newPodcast.summary = doc.innerText;
    //      newPodcast.courseDate = new Date(new Date().setDate(new Date().getDate() - this.getRandomInt(1,300)));
    //      newPodcast.pubDate = item.pubDate;

    //      this.podcasts.push(newPodcast);
    //      //item.CourseDate = new Date(new Date().setDate(new Date().getDate() - Math.floor((Math.random(0, 100)) * 100)));
    //      //item.title = item.title.replace(/Episode .*: /, '').replace(/ for Pankaj/, '')
    //      //item.description = item.description.replace("<p>The Project Management Podcast™ is a trademark of OSP International LLC. All other trademarks mentioned are the property of their respective owners. The Project Management Podcast™ and its RSS feed are copyright © by OSP International LLC 2005 - 2020. All rights reserved. </p>", "");
    //      //item.summary.__text = item.summary.__text.replace("The Project Management Podcast™ is a trademark of OSP International LLC. All other trademarks mentioned are the property of their respective owners. The Project Management Podcast™ and its RSS feed are copyright © by OSP International LLC 2005 - 2020. All rights reserved.", '');
    //    }
    //  })
      
    //});
  }

  getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  getDuration(src):any {
  return new Promise(function (resolve) {
    var audio = new Audio();
    $(audio).on("loadedmetadata", function () {
      resolve(audio);
    });
    audio.src = src;
  });
  }

  export() {
    this.excelService.exportAsExcelFile(this.podcasts, 'sample');
  }
  ngOnInit() {
    
  }

}

export class PodCast {
  title: string;
  description: string;
  pubDate: Date;
  courseDate: Date;
  summary: string;
  url: string;
  duration: number;
}
