import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListSupplierValue } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class ListValuesService {
  http: HttpClient;
  baseUrl: string;
  listValues: ListValue[];

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getKPITypes(): ListValue[] {
    this.listValues = [
      { id: '1', name: 'Standard', description: '', sequence: 1 },
      { id: '2', name: 'Standard + Scope', description: '', sequence: 2 },
      { id: '3', name: 'Special', description: '', sequence: 3 },
      { id: '4', name: 'Multiple Choice', description: '', sequence: 4 },
      { id: '5', name: 'Suppliers', description: '', sequence: 5 },
      { id: '6', name: 'Calculated', description: '', sequence: 6 },
      { id: '10', name: 'Centers', description: '', sequence: 10 },
      { id: '11', name: 'Courses', description: '', sequence: 11 },
      { id: '12', name: 'Journals', description: '', sequence: 12 },
      { id: '13', name: 'Faculty', description: '', sequence: 13 },
      { id: '14', name: 'Alumni Impact', description: '', sequence: 14 }
    ];
    return this.listValues;
  }


  getFiscalYears(): ListValue[] {
    this.listValues = [
      { id: '0', name: 'All', description: '', sequence: 0 },
      { id: '2022', name: '2022', description: '', sequence: 95 },
      { id: '2021', name: '2021', description: '', sequence: 96 },
      { id: '2020', name: '2020', description: '', sequence: 97 },
      { id: '2019', name: '2019', description: '', sequence: 98 },
      { id: '2018', name: '2018', description: '', sequence: 99 }
    ];
    return this.listValues;
  }

  getImportTypes(): ListValue[] {
    this.listValues = [
      { id: 'Corp', name: 'Corporations', description: '', sequence: 1 },
      { id: 'KPI_Overwrite', name: 'KPI Data - Overwrite All', description: '', sequence: 2 },
      { id: 'KPI_CK_Only', name: 'KPI Data - Overwrite CK', description: '', sequence: 3 },
      { id: 'KPI_Backfill', name: 'KPI Data - Backfill Only', description: '', sequence: 4 },
      { id: 'KPI_FIRE', name: 'KPI Data - FIRE', description: '', sequence: 5 },
      { id: 'Red_Flags', name: 'Red flags', description: '', sequence: 6 },
      { id: 'KPIGEN', name: 'KPI Loader File Generate', description: '', sequence: 7 },
      { id: 'User', name: 'Corporate Users', description: '', sequence: 8 },
    ];
    return this.listValues;
  }

  getLastRebalance(): Observable<MsgItem> {
    var msg = this.http.get<MsgItem>(this.baseUrl + 'api/listvalues/getlastrebalance');
    return msg;
  }

  getCurrentFiscal(): Observable<string> {
    return this.http.get<string>(this.baseUrl + 'api/listvalues/getcurrentfiscal');
  }

  GetReadOnly(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/listvalues/getreadonly');
  }

  SetReadOnly(valu: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/listvalues/setreadonly/' + valu);
  }

  SetOutliersEnabled(valu: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/listvalues/setoutliersenabled/' + valu);
  }

  GetOutliersEnabled(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/listvalues/getoutliersenabled');
  }

  getCountries(): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/country');
  }

  getCurrencies(): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/currency');
  }

  getUniverses(): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/universe');
  }

  getRoles(userId: string): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/listRoles/' + userId);
  }

  getIndustryGroups(): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/industrygroup');
  }

  getKPIHistory(companyId: string): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/kpihistory:' + companyId);
  }

  getCompanies(companyId: string): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/companies:' + companyId);
  }

  getKPIs(kpiId: string): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/kpis:' + kpiId);
  }

  getSuppliers(companyId: string): Observable<MultiselectListValue[]> {
    return this.http.get<MultiselectListValue[]>(this.baseUrl + `api/listvalues/listKpiList/${'suppliers'}`);
  }

  getCompaniesMulti(companyId: string): Observable<MultiCompanyselectListValue[]> {
    return this.http.get<MultiCompanyselectListValue[]>(this.baseUrl + `api/listvalues/listKpiList/${'companies'}`);
  }

  getUniversesMulti(): Observable<MultiselectListValue[]> {
    return this.http.get<MultiselectListValue[]>(this.baseUrl + `api/listvalues/listKpiList/${'universes'}`);
  }

  //getKPIs(): Observable<ListValue[]> {
  //  return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/kpi');
  //}

  getKPIList(): Observable<MultiselectListValue[]> {
    return this.http.get<MultiselectListValue[]>(this.baseUrl + `api/listvalues/listKpiList/${'kpi'}`);
  }

  getKPIListSurvey(): Observable<MultiselectListValue[]> {
    return this.http.get<MultiselectListValue[]>(this.baseUrl + `api/listvalues/listKpiList/${'kpisurvey'}`);
  }

  getKPIOptions(companyId: number): Observable<ListValue[]> {
    return this.http.get<ListValue[]>(this.baseUrl + 'api/listvalues/listkpioptions/' + companyId);
  }

  getEmailLog(): Observable<EmailLog[]> {
    return this.http.get<EmailLog[]>(this.baseUrl + 'api/listvalues/getemaillog');
  }

  getActvityLog(): Observable<ActivityLog[]> {
    return this.http.get<ActivityLog[]>(this.baseUrl + 'api/listvalues/getactivitylog');
  }



  // Import/Export Routines
  exportKPI(fiscalYear: string): Observable<ExportCompanyKPI[]> {
    return this.http.get<ExportCompanyKPI[]>(this.baseUrl + 'api/listvalues/exportcompanykpis/' + fiscalYear);
  }

  exportCompanies(): Observable<ExportCompanies[]> {
    return this.http.get<ExportCompanies[]>(this.baseUrl + 'api/listvalues/exportcompanies/');
  }

  exportKPIs(): Observable<ExportKPIs[]> {
    return this.http.get<ExportKPIs[]>(this.baseUrl + 'api/listvalues/exportkpis/');
  }

  exportUsers(): Observable<ExportUsers[]> {
    return this.http.get<ExportUsers[]>(this.baseUrl + 'api/listvalues/exportusers/');
  }

  syncDatabases(): Observable<SyncDatabases> {
    return this.http.get<SyncDatabases>(this.baseUrl + 'api/listvalues/syncdatabases/');
  }

  publishData(): Observable<publishData> {
    return this.http.get<publishData>(this.baseUrl + 'api/listvalues/publishdata/');
  }

  exportQAOutput(params: string): Observable<ExportQAOutput[]> {
    return this.http.get<ExportQAOutput[]>(this.baseUrl + 'api/listvalues/exportqaoutput/' + params);
  }

  exportDataReport(params: string): Observable<ExportDataReport[]> {
    return this.http.get<ExportDataReport[]>(this.baseUrl + 'api/listvalues/exportdatareport/' + params);
  }

  exportFIREData(params: string): Observable<ExportFIREData[]> {
    return this.http.get<ExportFIREData[]>(this.baseUrl + 'api/listvalues/exportfiredata/' + params);
  }

  exportSEISummary1(params: string): Observable<ExportSEISummary1[]> {
    return this.http.get<ExportSEISummary1[]>(this.baseUrl + 'api/listvalues/exportseisummary1/' + params);
  }

  exportSEISummary2(params: string): Observable<ExportSEISummary2[]> {
    return this.http.get<ExportSEISummary2[]>(this.baseUrl + 'api/listvalues/exportseisummary2/' + params);
  }

  exportSEIDeltas(params: string): Observable<ExportSEIDeltas[]> {
    return this.http.get<ExportSEIDeltas[]>(this.baseUrl + 'api/listvalues/exportseideltas/' + params);
  }

  exportSEIQuickReport(): Observable<ExportSEIQuickReport[]> {
    return this.http.get<ExportSEIQuickReport[]>(this.baseUrl + 'api/listvalues/exportseiquickreport/');
  }

  exportDataReportMetadata(): Observable<ExportDataReportMetadata[]> {
    return this.http.get<ExportDataReportMetadata[]>(this.baseUrl + 'api/listvalues/exportdatareportmetadata/');
  }

  exportScorecardInput(): Observable<ExportScorecardInput[]> {
    return this.http.get<ExportScorecardInput[]>(this.baseUrl + 'api/listvalues/exportscorecardinput/');
  }

  exportFillRates(): Observable<ExportFillRates[]> {
    return this.http.get<ExportFillRates[]>(this.baseUrl + 'api/listvalues/getfillrates/');
  }

  importCompanies(companies: ImportCompanies[]): Observable<any> {
    var result = this.http.post(this.baseUrl + 'api/listvalues/importCompanies/', companies);
    return result;
  }

  importFlags(flags: ImportFlags[]): Observable<any> {
    var result = this.http.post(this.baseUrl + 'api/listvalues/importFlags/', flags);
    return result;
  }

  importKPIs(kpis: ImportKPIs[], type: string): Observable<MsgItem> {
    //debugger;
    switch (type) {
      case 'KPI_Overwrite':
        return this.http.put<MsgItem>(this.baseUrl + 'api/listvalues/importkpisspoverwriteall/', kpis);
        break;
      case 'KPI_CK_Only':
        return this.http.put<MsgItem>(this.baseUrl + 'api/listvalues/importkpisspoverwriteck/', kpis);
        break;
      case 'KPI_Backfill':
        return this.http.put<MsgItem>(this.baseUrl + 'api/listvalues/importkpisspbackfill/', kpis);
        break;
      case 'KPI_FIRE':
        return this.http.put<MsgItem>(this.baseUrl + 'api/listvalues/importkpisspfire/', kpis);
        break;
    }

  }

  importKPIsGenerate(kpis: ImportKPIs[]): Observable<MsgItem> {
    return this.http.put<MsgItem>(this.baseUrl + 'api/listvalues/importkpisgenerate/', kpis);
  }

  importUsers(users: ImportUsers[]): Observable<MsgItem> {
    var result = this.http.put<MsgItem>(this.baseUrl + 'api/users/importusers/', users);
    return result;
  }

  //importUsersEmail(users: ImportUsers[]): Observable<MsgItem> {
  //  var result = this.http.put<MsgItem>(this.baseUrl + 'api/users/importusersemail/', users);
  //  return result;
  //}
}

export interface ListValue {
  id: string;
  name: string;
  description: string;
  sequence: number;
}

export interface MultiselectListValue {
  text_id: string;
  text_name: string;
}

export interface MultiCompanyselectListValue {
  item_id: number;
  item_text: string;
}

export interface MultiUniverseselectListValue {
  item_id: number;
  item_text: string;
}

export interface EmailLog {
  id: number;
  emailTemplateId: number;
  companyId: number;
  permid: string;
  recipient: string;
  title: string;
  body: string;
  sendDate: string;
  status: string;
}

export interface ActivityLog {
  id: number;
  email: string;
  guid: string;
  companyId: number;
  permid: string;
  action: string;
  notes: string;
  modifiedDate: string;
  status: string;
}

export interface ExportFillRates {
  total_companies: string;
  clean_revenue_companies: string;
  clean_Investment_companies: string;
  record_Date: string;
}

export interface ExportListValue {
  id: string;
  name: string;
  description: string;
  sequence: number;
}

export interface ImportListValue {
  id: string;
  name: string;
  description: string;
  sequence: number;
}

export interface ExportUsers {
  email: string;
  firstName: string;
  lastName: string;
  permid: string;
  userRole: string;
  title: string;
  titleCategorization: string;
  source: string;
  respondedTo: string;
}

export interface SyncDatabases {
  message: string;
}

export interface publishData {
  message: string;
}

export interface ExportSEIQuickReport {
  rating_Year: number;
  number_Reviewed_Companies_SR: number;
  number_Companies_non_zero_SR: number;
  total_SR_PPP_Billions: number;
  gross_Revenue_PPP_Billions: number;
  number_Reviewed_Companies_SI: number;
  number_Companies_non_zero_SI: number;
  total_SI_PPP_Billions: number;
  total_Investment_PPP_Billions: number;
}

export interface ExportFIREData {
  date: string;
  company_Name: string;
  permid: string;
  ckpg: string;
  category: string;
  tier_1_Subcategory: string;
  tier_2_Subcategory: string;
  notes: string;
  reference: string;
  source: string;
  cK_Notes: string;
  zero_Type: string;
  annual_or_Cumulative: string;
  environmental_or_Social: string;
  rating_Year: string;
  total_Outstanding_Loans: string;
  total_Sustainable_Loans: string;
  annual_Loan_Interest_Revenue: string;
  total_AUM_Third_party: string;
  sustainable_AUM_Third_party: string;
  management_Fee_Revenue: string;
  total_AUM_General_account: string;
  sustainable_AUM_general_account: string;
  realized_Capital_Gains: string;
  dividends: string;
  sustainable_Underwriting_Volume: string;
  sustainable_Advisory_Volume: string;
  underwriting_Proxy: string;
  sustainable_Underwriting_Revenue_actual: string;
  sustainable_Advisory_Revenue_actual: string;
  enviro_and_Health_Insurance_Premiums: string;
  other_Sustainable_Revenue: string;
  sustainable_Revenue_live_calculation: string;
  sustainable_Revenue_direct_portal_input: string;
  analyst: string;
  qA_Analyst: string;
  qA_Date: string;
  company_Correspondence_Data: string;
  years_of_Commitment: string;
  concat: string;
  kpiCode: string;
}

export interface ExportSEISummary1 {
  date_of_report: string;
  name: string;
  isin: string;
  permid: string;
  ckpg: string;
  gicS_Industry: string;
  country: string;
  y1_PPP_Revenue: string;
  y2_PPP_Revenue: string;
  y3_PPP_Revenue: string;
  y4_PPP_Revenue: string;
  y5_PPP_Revenue: string;
  y1_PPP_Total_Investment: string;
  y2_PPP_Total_Investment: string;
  y3_PPP_Total_Investment: string;
  y4_PPP_Total_Investment: string;
  y5_PPP_Total_Investment: string;
  y1_Sustainable_Revenue_Total_PPP: string;
  y1_Sustainable_Revenue_PPP_Percent_of_Total: string;
  y2_Sustainable_Revenue_Total_PPP: string;
  y2_Sustainable_Revenue_PPP_Percent_of_Total: string;
  y3_Sustainable_Revenue_Total_PPP: string;
  y3_Sustainable_Revenue_PPP_Percent_of_Total: string;
  y4_Sustainable_Revenue_Total_PPP: string;
  y4_Sustainable_Revenue_PPP_Percent_of_Total: string;
  y5_Sustainable_Revenue_Total_PPP: string;
  y5_Sustainable_Revenue_PPP_Percent_of_Total: string;
  y1_Sustainable_Investment_Total_PPP: string;
  y1_Sustainable_Investment_PPP_Percent_of_Total: string;
  y2_Sustainable_Investment_Total_PPP: string;
  y2_Sustainable_Investment_PPP_Percent_of_Total: string;
  y3_Sustainable_Investment_Total_PPP: string;
  y3_Sustainable_Investment_PPP_Percent_of_Total: string;
  y4_Sustainable_Investment_Total_PPP: string;
  y4_Sustainable_Investment_PPP_Percent_of_Total: string;
  y5_Sustainable_Investment_Total_PPP: string;
  y5_Sustainable_Investment_PPP_Percent_of_Total: string;
  y1_PPP_Total_Capex: string;
  y2_PPP_Total_Capex: string;
  y3_PPP_Total_Capex: string;
  y4_PPP_Total_Capex: string;
  y5_PPP_Total_Capex: string;
  y1_PPP_Total_Sustainable_Capex: string;
  y2_PPP_Total_Sustainable_Capex: string;
  y3_PPP_Total_Sustainable_Capex: string;
  y4_PPP_Total_Sustainable_Capex: string;
  y5_PPP_Total_Sustainable_Capex: string;
  y1_PPP_Total_R_n_D: string;
  y2_PPP_Total_R_n_D: string;
  y3_PPP_Total_R_n_D: string;
  y4_PPP_Total_R_n_D: string;
  y5_PPP_Total_R_n_D: string;
  y1_PPP_Total_Sustainable_R_n_D: string;
  y2_PPP_Total_Sustainable_R_n_D: string;
  y3_PPP_Total_Sustainable_R_n_D: string;
  y4_PPP_Total_Sustainable_R_n_D: string;
  y5_PPP_Total_Sustainable_R_n_D: string;
  y1_PPP_Total_Acquisitions: string;
  y2_PPP_Total_Acquisitions: string;
  y3_PPP_Total_Acquisitions: string;
  y4_PPP_Total_Acquisitions: string;
  y5_PPP_Total_Acquisitions: string;
  y1_PPP_Total_Sustainable_Acquisitions: string;
  y2_PPP_Total_Sustainable_Acquisitions: string;
  y3_PPP_Total_Sustainable_Acquisitions: string;
  y4_PPP_Total_Sustainable_Acquisitions: string;
  y5_PPP_Total_Sustainable_Acquisitions: string;
}


export interface ExportSEISummary2 {
  date_of_report: string;
  company_Name: string;
  permid: string;
  isin: string;
  ckpG_Name: string;
  location: string;
  category: string;
  tier_1_Subcategory: string;
  tier_2_Subcategory: string;
  ppP_Value: string;
  notes: string;
  reference: string;
  source: string;
  rating_Year: string;
  capex_Type: string;
  zero_Type: string;
  proxy: string;
  years_of_Commitment: string;
  cK_Notes: string;
}

export interface ExportSEIDeltas {
  company_Name: string;
  isin: string;
  permid: string;
  ckpG_Name: string;
  location: string;
  y1_sustainable_revenue_ratio: string;
  y1_sustainable_investment_ratio: string;
  y1_sustainable_acquisitions_ratio: string;
  y1_sustainable_capex_ratio: string;
  y1_sustainable_RnD_ratio: string;
  y2_sustainable_revenue_ratio: string;
  y2_sustainable_investment_ratio: string;
  y2_sustainable_acquisitions_ratio: string;
  y2_sustainable_capex_ratio: string;
  y2_sustainable_RnD_ratio: string;
  y3_sustainable_revenue_ratio: string;
  y3_sustainable_investment_ratio: string;
  y3_sustainable_acquisitions_ratio: string;
  y3_sustainable_capex_ratio: string;
  y3_sustainable_RnD_ratio: string;
  y4_sustainable_revenue_ratio: string;
  y4_sustainable_investment_ratio: string;
  y4_sustainable_acquisitions_ratio: string;
  y4_sustainable_capex_ratio: string;
  y4_sustainable_RnD_ratio: string;
  y5_sustainable_revenue_ratio: string;
  y5_sustainable_investment_ratio: string;
  y5_sustainable_acquisitions_ratio: string;
  y5_sustainable_capex_ratio: string;
  y5_sustainable_RnD_ratio: string;
  y1_y2_SR_delta_absolute: string;
  y1_y2_SI_delta_absolute: string;
  y1_y2_sustainable_acquisitions_delta_absolute: string;
  y1_y2_sustainable_capex_delta_absolute: string;
  y1_y2_sustainable_RnD_delta_absolute: string;
  y2_y3_SR_delta_absolute: string;
  y2_y3_SI_delta_absolute: string;
  y2_y3_sustainable_acquisitions_delta_absolute: string;
  y2_y3_sustainable_capex_delta_absolute: string;
  y2_y3_sustainable_RnD_delta_absolute: string;
  y3_y4_SR_delta_absolute: string;
  y3_y4_SI_delta_absolute: string;
  y3_y4_sustainable_acquisitions_delta_absolute: string;
  y3_y4_sustainable_capex_delta_absolute: string;
  y3_y4_sustainable_RnD_delta_absolute: string;
  y4_y5_SR_delta_absolute: string;
  y4_y5_SI_delta_absolute: string;
  y4_y5_sustainable_acquisitions_delta_absolute: string;
  y4_y5_sustainable_capex_delta_absolute: string;
  y4_y5_sustainable_RnD_delta_absolute: string;
  y1_y2_SR_delta: string;
  y1_y2_SI_delta: string;
  y1_y2_sustainable_acquisitions_delta: string;
  y1_y2_sustainable_capex_delta: string;
  y1_y2_sustainable_RnD_delta: string;
  y2_y3_SR_delta: string;
  y2_y3_SI_delta: string;
  y2_y3_sustainable_acquisitions_delta: string;
  y2_y3_sustainable_capex_delta: string;
  y2_y3_sustainable_RnD_delta: string;
  y3_y4_SR_delta: string;
  y3_y4_SI_delta: string;
  y3_y4_sustainable_acquisitions_delta: string;
  y3_y4_sustainable_capex_delta: string;
  y3_y4_sustainable_RnD_delta: string;
  y4_y5_SR_delta: string;
  y4_y5_SI_delta: string;
  y4_y5_sustainable_acquisitions_delta: string;
  y4_y5_sustainable_capex_delta: string;
  y4_y5_sustainable_RnD_delta: string;
  
}

export interface ExportQAOutput {
  permid: string;
  name: string;
  ckpg: string;
  ckpG_Name: string;
  highest_Weighted_KPI: string;
  second_Highest_Weighted_KPI: string;
  third_Highest_Weighted_KPI: string;
  data_For_Highest_Weighted_KPI_2023: string;
  data_For_Second_Highest_Weighted_KPI_2023: string;
  data_For_Third_Highest_Weighted_KPI_2023: string;
  currency: string;
  purchasing_Power_Parity_Conversion_Rate_2020: string;
  purchasing_Power_Parity_Conversion_Rate_2021: string;
  purchasing_Power_Parity_Conversion_Rate_2022: string;
  purchasing_Power_Parity_Conversion_Rate_2023: string;
  country_of_headquarters: string;
  isin: string;
  instrument_is_active: string;
  ticker: string;
  adR_ticker: string;
  f_Score: string;
  company_Response_on_Portal: string;
  gicS_Industry_Group: string;
  gicS_Industry: string;
  gicS_Sector: string;
  global_100_last_year: string;
  purchasing_Power_Parity_Revenue_2020: string;
  purchasing_Power_Parity_Revenue_2021: string;
  purchasing_Power_Parity_Revenue_2022: string;
  purchasing_Power_Parity_Revenue_2023: string;
  energy_Use_RM01_2020: string;
  energy_Use_RM01_2021: string;
  energy_Use_RM01_2022: string;
  energy_Use_RM01_2023: string;
  renewable_Energy_Use_RM02_2020: string;
  renewable_Energy_Use_RM02_2021: string;
  renewable_Energy_Use_RM02_2022: string;
  renewable_Energy_Use_RM02_2023: string;
  total_Energy_Use_2023: string;
  energy_Productivity_2022: string;
  energy_Productivity_2023: string;
  energy_Productivity_percentrank_2023: string;
  energy_Productivity_change_2023: string;
  energy_Productivity_change_percentrank_2023: string;
  energy_Productivity_change_percentrank_multiplier_2023: string;
  energy_Productivity_Score_2022: string;
  energy_Productivity_Score_2023: string;
  scope_1_GHG_Emissions_RM03_2020: string;
  scope_1_GHG_Emissions_RM03_2021: string;
  scope_1_GHG_Emissions_RM03_2022: string;
  scope_1_GHG_Emissions_RM03_2023: string;
  scope_2_GHG_Emissions_RM04_2020: string;
  scope_2_GHG_Emissions_RM04_2021: string;
  scope_2_GHG_Emissions_RM04_2022: string;
  scope_2_GHG_Emissions_RM04_2023: string;
  market_Based_Scope_2_GHG_Emissions_RM004_2020: string;
  market_Based_Scope_2_GHG_Emissions_RM004_2021: string;
  market_Based_Scope_2_GHG_Emissions_RM004_2022: string;
  market_Based_Scope_2_GHG_Emissions_RM004_2023: string;
  total_Carbon_2023: string;
  carbon_Productivity_2022: string;
  carbon_Productivity_2023: string;
  carbon_Productivity_percentrank_2023: string;
  carbon_Productivity_change_2023: string;
  carbon_Productivity_change_percentrank_2023: string;
  carbon_Productivity_change_percentrank_multiplier_2023: string;
  carbon_Productivity_Score_2022: string;
  carbon_Productivity_Score_2023: string;
  water_use_or_withdrawal_RM06_2020: string;
  water_use_or_withdrawal_RM06_2021: string;
  water_use_or_withdrawal_RM06_2022: string;
  water_use_or_withdrawal_RM06_2023: string;
  water_reused_or_recycled_RM16_2020: string;
  water_reused_or_recycled_RM16_2021: string;
  water_reused_or_recycled_RM16_2022: string;
  water_reused_or_recycled_RM16_2023: string;
  water_consumption_RM14_2020: string;
  water_consumption_RM14_2021: string;
  water_consumption_RM14_2022: string;
  water_consumption_RM14_2023: string;
  water_discharged_RM15_2020: string;
  water_discharged_RM15_2021: string;
  water_discharged_RM15_2022: string;
  water_discharged_RM15_2023: string;
  water_Productivity_2022: string;
  water_Productivity_2023: string;
  water_Productivity_percentrank_2023: string;
  water_Productivity_change_2023: string;
  water_Productivity_change_percentrank_2023: string;
  water_Productivity_change_percentrank_multiplier_2023: string;
  water_Productivity_Score_2022: string;
  water_Productivity_Score_2023: string;
  water_Material_CKPG: string;
  water_Whole_Universe_Percentrank_2023: string;
  water_Top_Quartile_Percentrank_2023: string;
  water_Penalty: string;
  total_waste_generated_RM07_2020: string;
  total_waste_generated_RM07_2021: string;
  total_waste_generated_RM07_2022: string;
  total_waste_generated_RM07_2023: string;
  waste_recycled_RM08_2020: string;
  waste_recycled_RM08_2021: string;
  waste_recycled_RM08_2022: string;
  waste_recycled_RM08_2023: string;
  waste_rock_RM13_2020: string;
  waste_rock_RM13_2021: string;
  waste_rock_RM13_2022: string;
  waste_rock_RM13_2023: string;
  tailings_RM17_2020: string;
  tailings_RM17_2021: string;
  tailings_RM17_2022: string;
  tailings_RM17_2023: string;
  net_Waste_2022: string;
  net_Waste_2023: string;
  waste_Productivity_2022: string;
  waste_Productivity_2023: string;
  waste_Productivity_percentrank_2023: string;
  waste_Productivity_change_2023: string;
  waste_Productivity_change_percentrank_2023: string;
  waste_Productivity_change_percentrank_multiplier_2023: string;
  waste_Productivity_Score_2022: string;
  waste_Productivity_Score_2023: string;
  waste_Material_CKPG: string;
  waste_Whole_Universe_Percentrank_2023: string;
  waste_Top_Quartile_Percentrank_2023: string;
  waste_Penalty: string;
  voC_Emissions_RM09_2020: string;
  voC_Emissions_RM09_2021: string;
  voC_Emissions_RM09_2022: string;
  voC_Emissions_RM09_2023: string;
  voC_Productivity_2022: string;
  voC_Productivity_2023: string;
  voC_Productivity_percentrank_2023: string;
  voC_Productivity_change_2023: string;
  voC_Productivity_change_percentrank_2023: string;
  voC_Productivity_change_percentrank_multiplier_2023: string;
  voC_Productivity_Score_2022: string;
  voC_Productivity_Score_2023: string;
  voC_Material_CKPG: string;
  voC_Whole_Universe_Percentrank_2023: string;
  voC_Top_Quartile_Percentrank_2023: string;
  voC_Penalty: string;
  noX_Emissions_RM10_2020: string;
  noX_Emissions_RM10_2021: string;
  noX_Emissions_RM10_2022: string;
  noX_Emissions_RM10_2023: string;
  noX_Productivity_2022: string;
  noX_Productivity_2023: string;
  noX_Productivity_percentrank_2023: string;
  noX_Productivity_change_2023: string;
  noX_Productivity_change_percentrank_2023: string;
  noX_Productivity_change_percentrank_multiplier_2023: string;
  noX_Productivity_Score_2022: string;
  noX_Productivity_Score_2023: string;
  noX_Material_CKPG: string;
  noX_Whole_Universe_Percentrank_2023: string;
  noX_Top_Quartile_Percentrank_2023: string;
  noX_Penalty: string;
  soX_Emissions_RM11_2020: string;
  soX_Emissions_RM11_2021: string;
  soX_Emissions_RM11_2022: string;
  soX_Emissions_RM11_2023: string;
  soX_Productivity_2022: string;
  soX_Productivity_2023: string;
  soX_Productivity_percentrank_2023: string;
  soX_Productivity_change_2023: string;
  soX_Productivity_change_percentrank_2023: string;
  soX_Productivity_change_percentrank_multiplier_2023: string;
  soX_Productivity_Score_2022: string;
  soX_Productivity_Score_2023: string;
  soX_Material_CKPG: string;
  soX_Whole_Universe_Percentrank_2023: string;
  soX_Top_Quartile_Percentrank_2023: string;
  soX_Penalty: string;
  pM_Emissions_RM12_2020: string;
  pM_Emissions_RM12_2021: string;
  pM_Emissions_RM12_2022: string;
  pM_Emissions_RM12_2023: string;
  pM_Productivity_2022: string;
  pM_Productivity_2023: string;
  pM_Productivity_percentrank_2023: string;
  pM_Productivity_change_2023: string;
  pM_Productivity_change_percentrank_2023: string;
  pM_Productivity_change_percentrank_multiplier_2023: string;
  pM_Productivity_Score_2022: string;
  pM_Productivity_Score_2023: string;
  pM_Material_CKPG: string;
  pM_Whole_Universe_Percentrank_2023: string;
  pM_Top_Quartile_Percentrank_2023: string;
  pM_Penalty: string;
  cash_Taxes_Paid_TX01_2018: string;
  cash_Taxes_Paid_TX01_2019: string;
  cash_Taxes_Paid_TX01_2020: string;
  cash_Taxes_Paid_TX01_2021: string;
  cash_Taxes_Paid_TX01_2022: string;
  cash_Taxes_Paid_TX01_2023: string;
  cash_Taxes_Paid_Sum_2023: string;
  depreciation_Amortization_EA02_2018: string;
  depreciation_Amortization_EA02_2019: string;
  depreciation_Amortization_EA02_2020: string;
  depreciation_Amortization_EA02_2021: string;
  depreciation_Amortization_EA02_2022: string;
  depreciation_Amortization_EA02_2023: string;
  net_Income_Before_Taxes_EA03_2018: string;
  net_Income_Before_Taxes_EA03_2019: string;
  net_Income_Before_Taxes_EA03_2020: string;
  net_Income_Before_Taxes_EA03_2021: string;
  net_Income_Before_Taxes_EA03_2022: string;
  net_Income_Before_Taxes_EA03_2023: string;
  interest_Expense_EA04_2018: string;
  interest_Expense_EA04_2019: string;
  interest_Expense_EA04_2020: string;
  interest_Expense_EA04_2021: string;
  interest_Expense_EA04_2022: string;
  interest_Expense_EA04_2023: string;
  nibitdA_EA01_2018: string;
  nibitdA_EA01_2019: string;
  nibitdA_EA01_2020: string;
  nibitdA_EA01_2021: string;
  nibitdA_EA01_2022: string;
  nibitdA_EA01_2023: string;
  nibitdA_Sum_2023: string;
  cash_Taxes_Refunded_TX02_2018: string;
  cash_Taxes_Refunded_TX02_2019: string;
  cash_Taxes_Refunded_TX02_2020: string;
  cash_Taxes_Refunded_TX02_2021: string;
  cash_Taxes_Refunded_TX02_2022: string;
  cash_Taxes_Refunded_TX02_2023: string;
  cash_Taxes_Sum_Over_NIBITDA_Sum_2023: string;
  eligible_Revenue_Sum_2023: string;
  cash_Taxes_Sum_Over_NIBITDA_Sum_Median_2023: string;
  cash_Taxes_Paid_Ratio_2022: string;
  cash_Taxes_Paid_Ratio_2023: string;
  cash_Taxes_Paid_Score_2022: string;
  cash_Taxes_Paid_Score_2023: string;
  cash_Taxes_Paid_CKPG_Group: string;
  cash_Taxes_Paid_Whole_Universe_NIBITDA_Percentrank_2023: string;
  cash_Taxes_Paid_Top_Decile_Ratio_Percentrank_2023: string;
  cash_Taxes_Paid_Penalty: string;
  local_Currency_Gross_Revenue_2022: string;
  local_Currency_Gross_Revenue_2023: string;
  clean_Revenue_Total_2022: string;
  ppP_Clean_Revenue_Total_2022: string;
  clean_Revenue_Total_2023: string;
  ppP_Clean_Revenue_Total_2023: string;
  clean_Investment_Total_2022: string;
  ppP_Clean_Investment_Total_2022: string;
  clean_Investment_Total_2023: string;
  ppP_Clean_Investment_Total_2023: string;
  clean_Revenue_Ratio_2022: string;
  clean_Revenue_Ratio_2023: string;
  clean_Revenue_Score_2022: string;
  clean_Revenue_Score_2023: string;
  local_Currency_Total_Investment_2022: string;
  ppP_Total_Investment_2022: string;
  local_Currency_Total_Investment_2023: string;
  ppP_Total_Investment_2023: string;
  clean_Investment_Ratio_2022: string;
  clean_Investment_Ratio_2023: string;
  clean_Investment_Score_2022: string;
  clean_Investment_Score_2023: string;
  total_permanent_and_temporary_employees_in_FTEs_PA01_2022: string;
  total_permanent_and_temporary_employees_in_FTEs_PA01_2023: string;
  total_employee_costs_PA02_2022: string;
  total_employee_costs_PA02_2023: string;
  total_CEO_compensation_PA03_2022: string;
  total_CEO_compensation_PA03_2023: string;
  total_Executive_compensation_PA06_2022: string;
  total_Executive_compensation_PA06_2023: string;
  ceO_Average_Worker_Pay_Ratio_2022: string;
  ceO_Average_Worker_Pay_Ratio_2023: string;
  ceO_Average_Worker_Pay_Score_2022: string;
  ceO_Average_Worker_Pay_Score_2023: string;
  employer_pension_and_retirement_Contributions_PE03_2022: string;
  employer_pension_and_retirement_Contributions_PE03_2023: string;
  retirement_contributions_FTE_2022: string;
  retirement_contributions_FTE_2023: string;
  retirement_contributions_FTE_Percentrank_2023: string;
  defined_benefit_pension_fair_value_plan_assets_PE01_2022: string;
  defined_benefit_pension_fair_value_plan_assets_PE01_2023: string;
  projected_Benefit_Obligation_FTE_2022: string;
  projected_Benefit_Obligation_FTE_2023: string;
  projected_Benefit_Obligation_FTE_Percentrank_2023: string;
  defined_benefit_pension_projected_benefit_obligation_PE02_2022: string;
  defined_benefit_pension_projected_benefit_obligation_PE02_2023: string;
  fair_value_projected_benefit_obligation_2022: string;
  fair_value_projected_benefit_obligation_2023: string;
  fair_value_projected_benefit_obligation_Percentrank_2023: string;
  pension_Fund_Quality_2022: string;
  pension_Fund_Quality_2023: string;
  injury_Rate_Ratio_2022: string;
  injury_Rate_Ratio_2023: string;
  injury_Rate_Score_2022: string;
  injury_Rate_Score_2023: string;
  injury_Rate_Material_CKPG: string;
  injury_Rate_Percentrank_2023: string;
  injury_Rate_Top_Quartile_Percentrank_2023: string;
  injury_Rate_Penalty: string;
  total_work_related_fatalities_HS02_2022: string;
  total_work_related_fatalities_HS02_2023: string;
  fatalities_Per_Employee_Ratio_2022: string;
  fatalities_Per_Employee_Ratio_2023: string;
  fatalities_Per_Employee_Score_2022: string;
  fatalities_Per_Employee_Score_2023: string;
  fatalities_Whole_Universe_Percentrank_2023: string;
  fatalities_Penalty: string;
  employee_Turnover_Ratio_2022: string;
  employee_Turnover_Ratio_2023: string;
  employee_Turnover_Score_2022: string;
  employee_Turnover_Score_2023: string;
  non_male_Board_Members_LD02_2022: string;
  non_male_Board_Members_LD02_2023: string;
  non_male_Board_Members_Direct_Input_LD102_2022: string;
  non_male_Board_Members_Direct_Input_LD102_2023: string;
  non_male_Executives_LD01_2022: string;
  non_male_Executives_LD01_2023: string;
  non_male_Executives_Direct_Input_LD101_2022: string;
  non_male_Executives_Direct_Input_LD101_2023: string;
  racially_Diverse_Board_Members_LD04_2022: string;
  racially_Diverse_Board_Members_LD04_2023: string;
  racially_Diverse_Executives_LD03_2022: string;
  racially_Diverse_Executives_LD03_2023: string;
  total_Board_Members_LD002_2022: string;
  total_Board_Members_LD002_2023: string;
  total_Executives_LD001_2022: string;
  total_Executives_LD001_2023: string;
  non_male_Boards_Ratio_2022: string;
  non_male_Boards_Ratio_2023: string;
  non_male_Boards_Score_2022: string;
  non_male_Boards_Score_2023: string;
  non_male_Executives_Ratio_2022: string;
  non_male_Executives_Ratio_2023: string;
  non_male_Executives_Score_2022: string;
  non_male_Executives_Score_2023: string;
  racially_Diverse_Among_Boards_Ratio_2022: string;
  racially_Diverse_Among_Boards_Ratio_2023: string;
  racially_Diverse_Among_Boards_Score_2022: string;
  racially_Diverse_Among_Boards_Score_2023: string;
  racially_Diverse_Among_Executives_Ratio_2022: string;
  racially_Diverse_Among_Executives_Ratio_2023: string;
  racially_Diverse_Among_Executives_Score_2022: string;
  racially_Diverse_Among_Executives_Score_2023: string;
  paid_Sick_Leave_Score_2022: string;
  paid_Sick_Leave_Score_2023: string;
  sustainability_Paylink_Score_2022: string;
  sustainability_Paylink_Score_2023: string;
  sustainability_Paylink_CEO_sustainable_compensation_SP04_2022: string;
  sustainability_Paylink_CEO_sustainable_compensation_SP04_2023: string;
  sustainability_Paylink_CEO_total_compensation_SP05_2022: string;
  sustainability_Paylink_CEO_total_compensation_SP05_2023: string;
  sustainability_Paylink_Variable_Ratio_2022: string;
  sustainability_Paylink_Variable_Ratio_2023: string;
  sustainability_Paylink_Variable_Ratio_Score_2022: string;
  sustainability_Paylink_Variable_Ratio_Score_2023: string;
  supplier_Score_2022: string;
  highest_Scoring_Supplier_ISIN_2022: string;
  supplier_Score_2023: string;
  highest_Scoring_Supplier_ISIN_2023: string;
  highest_Scoring_Supplier_Score_2023: string;
  political_Influence_1_PI002_2023: string;
  political_Influence_2_PI003_2023: string;
  political_Influence_3_PI004_2023: string;
  political_Influence_4_PI005_2023: string;
  political_Influence_Score_2023: string;
  fines_Sum_2023: string;
  fines_Percentrank_2023: string;
  energy_Weighted_Score_2023: string;
  carbon_Weighted_Score_2023: string;
  water_Weighted_Score_2023: string;
  waste_Weighted_Score_2023: string;
  noX_Weighted_Score_2023: string;
  soX_Weighted_Score_2023: string;
  voC_Weighted_Score_2023: string;
  pM_Weighted_Score_2023: string;
  cash_Taxes_Weighted_Score_2023: string;
  ceO_Pay_Weighted_Score_2023: string;
  pension_Fund_Quality_Weighted_Score_2023: string;
  injury_Rate_Weighted_Score_2023: string;
  fatalities_Weighted_Score_2023: string;
  employee_Turnover_Weighted_Score_2023: string;
  sick_Leave_Weighted_Score_2023: string;
  non_Male_Boards_Weighted_Score_2023: string;
  non_Male_Executives_Weighted_Score_2023: string;
  racially_Diverse_Boards_Weighted_Score_2023: string;
  racially_Diverse_Executives_Weighted_Score_2023: string;
  sustainability_Paylink_Weighted_Score_2023: string;
  sustainability_Paylink_Variable_Weighted_Score_2023: string;
  political_Influence_1_Weighted_Score_2023: string;
  political_Influence_2_Weighted_Score_2023: string;
  political_Influence_3_Weighted_Score_2023: string;
  sustainable_Revenue_Weighted_Score_2023: string;
  sustainable_Investment_Weighted_Score_2023: string;
  supplier_Weighted_Score_2023: string;
  fines_Deduction: string;
  overall_Score_2022: string;
  overall_Score_2023: string;
  access_to_Medicine: string;
  access_to_Nutrition: string;
  adult_Entertainment: string;
  blocking_Climate_Policy: string;
  cement_Carbon_Laggards: string;
  civilian_Firearms: string;
  controversial_Weapons: string;
  conventional_Weapons: string;
  deforestation_Palm_Oil_Laggards: string;
  energy: string;
  farm_Animal_Welfare: string;
  profits_From_Private_Prisons: string;
  forced_Labour_and_Genocide: string;
  gambling: string;
  gross_Corruption_Violation: string;
  harmful_Pesticides: string;
  illegal_Activity: string;
  oil_Sands_Laggards: string;
  severe_Environmental_Damage: string;
  severe_Human_Rights_Violations: string;
  thermal_Coal: string;
  tobacco: string;
  fossil_Fuel_Financing: string;
  government_Sanctions: string;
  blocking_Climate_Resolutions: string;
  count_of_Flags: string;

  //permid: string;
  //name: string;
  //ckpg: string;
  //ckpG_Name: string;
  //highest_Weighted_KPI: string;
  //second_Highest_Weighted_KPI: string;
  //third_Highest_Weighted_KPI: string;
  //data_For_Highest_Weighted_KPI_2023: string;
  //data_For_Second_Highest_Weighted_KPI_2023: string;
  //data_For_Third_Highest_Weighted_KPI_2023: string;
  //currency: string;
  //purchasing_Power_Parity_Conversion_Rate_2020: string;
  //purchasing_Power_Parity_Conversion_Rate_2021: string;
  //purchasing_Power_Parity_Conversion_Rate_2022: string;
  //purchasing_Power_Parity_Conversion_Rate_2023: string;
  //country_of_headquarters: string;
  //isin: string;
  //instrument_is_active: string;
  //ticker: string;
  //adR_ticker: string;
  //f_Score: string;
  //company_Response_on_Portal: string;
  //gicS_Industry_Group: string;
  //gicS_Industry: string;
  //gicS_Sector: string;
  //global_100_last_year: string;
  //purchasing_Power_Parity_Revenue_2020: string;
  //purchasing_Power_Parity_Revenue_2021: string;
  //purchasing_Power_Parity_Revenue_2022: string;
  //purchasing_Power_Parity_Revenue_2023: string;
  //energy_Use_RM01_2020: string;
  //energy_Use_RM01_2021: string;
  //energy_Use_RM01_2022: string;
  //energy_Use_RM01_2023: string;
  //renewable_Energy_Use_RM02_2020: string;
  //renewable_Energy_Use_RM02_2021: string;
  //renewable_Energy_Use_RM02_2022: string;
  //renewable_Energy_Use_RM02_2023: string;
  //total_Energy_Use_2023: string;
  //energy_Productivity_2022: string;
  //energy_Productivity_2023: string;
  //energy_Productivity_percentrank_2023: string;
  //energy_Productivity_change_2023: string;
  //energy_Productivity_change_percentrank_2023: string;
  //energy_Productivity_change_percentrank_multiplier_2023: string;
  //energy_Productivity_Score_2022: string;
  //energy_Productivity_Score_2023: string;
  //scope_1_GHG_Emissions_RM03_2020: string;
  //scope_1_GHG_Emissions_RM03_2021: string;
  //scope_1_GHG_Emissions_RM03_2022: string;
  //scope_1_GHG_Emissions_RM03_2023: string;
  //scope_2_GHG_Emissions_RM04_2020: string;
  //scope_2_GHG_Emissions_RM04_2021: string;
  //scope_2_GHG_Emissions_RM04_2022: string;
  //scope_2_GHG_Emissions_RM04_2023: string;
  //total_Carbon_2023: string;
  //carbon_Productivity_2022: string;
  //carbon_Productivity_2023: string;
  //carbon_Productivity_percentrank_2023: string;
  //carbon_Productivity_change_2023: string;
  //carbon_Productivity_change_percentrank_2023: string;
  //carbon_Productivity_change_percentrank_multiplier_2023: string;
  //carbon_Productivity_Score_2022: string;
  //carbon_Productivity_Score_2023: string;
  //water_use_or_withdrawal_RM06_2020: string;
  //water_use_or_withdrawal_RM06_2021: string;
  //water_use_or_withdrawal_RM06_2022: string;
  //water_use_or_withdrawal_RM06_2023: string;
  //water_reused_or_recycled_RM16_2020: string;
  //water_reused_or_recycled_RM16_2021: string;
  //water_reused_or_recycled_RM16_2022: string;
  //water_reused_or_recycled_RM16_2023: string;
  //water_consumption_RM14_2020: string;
  //water_consumption_RM14_2021: string;
  //water_consumption_RM14_2022: string;
  //water_consumption_RM14_2023: string;
  //water_discharged_RM15_2020: string;
  //water_discharged_RM15_2021: string;
  //water_discharged_RM15_2022: string;
  //water_discharged_RM15_2023: string;
  //water_Productivity_2022: string;
  //water_Productivity_2023: string;
  //water_Productivity_percentrank_2023: string;
  //water_Productivity_change_2023: string;
  //water_Productivity_change_percentrank_2023: string;
  //water_Productivity_change_percentrank_multiplier_2023: string;
  //water_Productivity_Score_2022: string;
  //water_Productivity_Score_2023: string;
  //water_Material_CKPG: string;
  //water_Whole_Universe_Percentrank_2023: string;
  //water_Top_Quartile_Percentrank_2023: string;
  //water_Penalty: string;
  //total_waste_generated_RM07_2020: string;
  //total_waste_generated_RM07_2021: string;
  //total_waste_generated_RM07_2022: string;
  //total_waste_generated_RM07_2023: string;
  //waste_recycled_RM08_2020: string;
  //waste_recycled_RM08_2021: string;
  //waste_recycled_RM08_2022: string;
  //waste_recycled_RM08_2023: string;
  //waste_rock_RM13_2020: string;
  //waste_rock_RM13_2021: string;
  //waste_rock_RM13_2022: string;
  //waste_rock_RM13_2023: string;
  //tailings_RM17_2020: string;
  //tailings_RM17_2021: string;
  //tailings_RM17_2022: string;
  //tailings_RM17_2023: string;
  //net_Waste_2022: string;
  //net_Waste_2023: string;
  //waste_Productivity_2022: string;
  //waste_Productivity_2023: string;
  //waste_Productivity_percentrank_2023: string;
  //waste_Productivity_change_2023: string;
  //waste_Productivity_change_percentrank_2023: string;
  //waste_Productivity_change_percentrank_multiplier_2023: string;
  //waste_Productivity_Score_2022: string;
  //waste_Productivity_Score_2023: string;
  //waste_Material_CKPG: string;
  //waste_Whole_Universe_Percentrank_2023: string;
  //waste_Top_Quartile_Percentrank_2023: string;
  //waste_Penalty: string;
  //voC_Emissions_RM09_2020: string;
  //voC_Emissions_RM09_2021: string;
  //voC_Emissions_RM09_2022: string;
  //voC_Emissions_RM09_2023: string;
  //voC_Productivity_2022: string;
  //voC_Productivity_2023: string;
  //voC_Productivity_percentrank_2023: string;
  //voC_Productivity_change_2023: string;
  //voC_Productivity_change_percentrank_2023: string;
  //voC_Productivity_change_percentrank_multiplier_2023: string;
  //voC_Productivity_Score_2022: string;
  //voC_Productivity_Score_2023: string;
  //voC_Material_CKPG: string;
  //voC_Whole_Universe_Percentrank_2023: string;
  //voC_Top_Quartile_Percentrank_2023: string;
  //voC_Penalty: string;
  //noX_Emissions_RM10_2020: string;
  //noX_Emissions_RM10_2021: string;
  //noX_Emissions_RM10_2022: string;
  //noX_Emissions_RM10_2023: string;
  //noX_Productivity_2022: string;
  //noX_Productivity_2023: string;
  //noX_Productivity_percentrank_2023: string;
  //noX_Productivity_change_2023: string;
  //noX_Productivity_change_percentrank_2023: string;
  //noX_Productivity_change_percentrank_multiplier_2023: string;
  //noX_Productivity_Score_2022: string;
  //noX_Productivity_Score_2023: string;
  //noX_Material_CKPG: string;
  //noX_Whole_Universe_Percentrank_2023: string;
  //noX_Top_Quartile_Percentrank_2023: string;
  //noX_Penalty: string;
  //soX_Emissions_RM11_2020: string;
  //soX_Emissions_RM11_2021: string;
  //soX_Emissions_RM11_2022: string;
  //soX_Emissions_RM11_2023: string;
  //soX_Productivity_2022: string;
  //soX_Productivity_2023: string;
  //soX_Productivity_percentrank_2023: string;
  //soX_Productivity_change_2023: string;
  //soX_Productivity_change_percentrank_2023: string;
  //soX_Productivity_change_percentrank_multiplier_2023: string;
  //soX_Productivity_Score_2022: string;
  //soX_Productivity_Score_2023: string;
  //soX_Material_CKPG: string;
  //soX_Whole_Universe_Percentrank_2023: string;
  //soX_Top_Quartile_Percentrank_2023: string;
  //soX_Penalty: string;
  //pM_Emissions_RM12_2020: string;
  //pM_Emissions_RM12_2021: string;
  //pM_Emissions_RM12_2022: string;
  //pM_Emissions_RM12_2023: string;
  //pM_Productivity_2022: string;
  //pM_Productivity_2023: string;
  //pM_Productivity_percentrank_2023: string;
  //pM_Productivity_change_2023: string;
  //pM_Productivity_change_percentrank_2023: string;
  //pM_Productivity_change_percentrank_multiplier_2023: string;
  //pM_Productivity_Score_2022: string;
  //pM_Productivity_Score_2023: string;
  //pM_Material_CKPG: string;
  //pM_Whole_Universe_Percentrank_2023: string;
  //pM_Top_Quartile_Percentrank_2023: string;
  //pM_Penalty: string;
  //cash_Taxes_Paid_TX01_2018: string;
  //cash_Taxes_Paid_TX01_2019: string;
  //cash_Taxes_Paid_TX01_2020: string;
  //cash_Taxes_Paid_TX01_2021: string;
  //cash_Taxes_Paid_TX01_2022: string;
  //cash_Taxes_Paid_TX01_2023: string;
  //cash_Taxes_Paid_Sum_2023: string;
  //ebitdA_EA01_2018: string;
  //ebitdA_EA01_2019: string;
  //ebitdA_EA01_2020: string;
  //ebitdA_EA01_2021: string;
  //ebitdA_EA01_2022: string;
  //ebitdA_EA01_2023: string;
  //ebitdA_Sum_2023: string;
  //cash_Taxes_Refunded_TX02_2018: string;
  //cash_Taxes_Refunded_TX02_2019: string;
  //cash_Taxes_Refunded_TX02_2020: string;
  //cash_Taxes_Refunded_TX02_2021: string;
  //cash_Taxes_Refunded_TX02_2022: string;
  //cash_Taxes_Refunded_TX02_2023: string;
  //cash_Taxes_Sum_Over_EBITDA_Sum_2023: string;
  //eligible_Revenue_Sum_2023: string;
  //cash_Taxes_Sum_Over_EBITDA_Sum_Median_2023: string;
  //cash_Taxes_Paid_Ratio_2022: string;
  //cash_Taxes_Paid_Ratio_2023: string;
  //cash_Taxes_Paid_Score_2022: string;
  //cash_Taxes_Paid_Score_2023: string;
  //cash_Taxes_Paid_CKPG_Group: string;
  //cash_Taxes_Paid_Whole_Universe_EBITDA_Percentrank_2023: string;
  //cash_Taxes_Paid_Top_Decile_Ratio_Percentrank_2023: string;
  //cash_Taxes_Paid_Penalty: string;
  //local_Currency_Gross_Revenue_2022: string;
  //local_Currency_Gross_Revenue_2023: string;
  //clean_Revenue_Total_2022: string;
  //ppP_Clean_Revenue_Total_2022: string;
  //clean_Revenue_Total_2023: string;
  //ppP_Clean_Revenue_Total_2023: string;
  //clean_Investment_Total_2022: string;
  //ppP_Clean_Investment_Total_2022: string;
  //clean_Investment_Total_2023: string;
  //ppP_Clean_Investment_Total_2023: string;
  //clean_Revenue_Ratio_2022: string;
  //clean_Revenue_Ratio_2023: string;
  //clean_Revenue_Score_2022: string;
  //clean_Revenue_Score_2023: string;
  //local_Currency_Total_Investment_2022: string;
  //ppP_Total_Investment_2022: string;
  //local_Currency_Total_Investment_2023: string;
  //ppP_Total_Investment_2023: string;
  //clean_Investment_Ratio_2022: string;
  //clean_Investment_Ratio_2023: string;
  //clean_Investment_Score_2022: string;
  //clean_Investment_Score_2023: string;
  //total_permanent_and_temporary_employees_in_FTEs_PA01_2022: string;
  //total_permanent_and_temporary_employees_in_FTEs_PA01_2023: string;
  //total_employee_costs_PA02_2022: string;
  //total_employee_costs_PA02_2023: string;
  //total_CEO_compensation_PA03_2022: string;
  //total_CEO_compensation_PA03_2023: string;
  //total_Executive_compensation_PA06_2022: string;
  //total_Executive_compensation_PA06_2023: string;
  //ceO_Average_Worker_Pay_Ratio_2022: string;
  //ceO_Average_Worker_Pay_Ratio_2023: string;
  //ceO_Average_Worker_Pay_Score_2022: string;
  //ceO_Average_Worker_Pay_Score_2023: string;
  //employer_pension_and_retirement_Contributions_PE03_2022: string;
  //employer_pension_and_retirement_Contributions_PE03_2023: string;
  //retirement_contributions_FTE_2022: string;
  //retirement_contributions_FTE_2023: string;
  //retirement_contributions_FTE_Percentrank_2023: string;
  //defined_benefit_pension_fair_value_plan_assets_PE01_2022: string;
  //defined_benefit_pension_fair_value_plan_assets_PE01_2023: string;
  //projected_Benefit_Obligation_FTE_2022: string;
  //projected_Benefit_Obligation_FTE_2023: string;
  //projected_Benefit_Obligation_FTE_Percentrank_2023: string;
  //defined_benefit_pension_projected_benefit_obligation_PE02_2022: string;
  //defined_benefit_pension_projected_benefit_obligation_PE02_2023: string;
  //fair_value_projected_benefit_obligation_2022: string;
  //fair_value_projected_benefit_obligation_2023: string;
  //fair_value_projected_benefit_obligation_Percentrank_2023: string;
  //pension_Fund_Quality_2022: string;
  //pension_Fund_Quality_2023: string;
  //injury_Rate_Ratio_2022: string;
  //injury_Rate_Ratio_2023: string;
  //injury_Rate_Score_2022: string;
  //injury_Rate_Score_2023: string;
  //injury_Rate_Material_CKPG: string;
  //injury_Rate_Percentrank_2023: string;
  //injury_Rate_Top_Quartile_Percentrank_2023: string;
  //injury_Rate_Penalty: string;
  //total_work_related_fatalities_HS02_2022: string;
  //total_work_related_fatalities_HS02_2023: string;
  //fatalities_Per_Employee_Ratio_2022: string;
  //fatalities_Per_Employee_Ratio_2023: string;
  //fatalities_Per_Employee_Score_2022: string;
  //fatalities_Per_Employee_Score_2023: string;
  //fatalities_Whole_Universe_Percentrank_2023: string;
  //fatalities_Penalty: string;
  //employee_Turnover_Ratio_2022: string;
  //employee_Turnover_Ratio_2023: string;
  //employee_Turnover_Score_2022: string;
  //employee_Turnover_Score_2023: string;
  //non_male_Board_Members_LD02_2022: string;
  //non_male_Board_Members_LD02_2023: string;
  //non_male_Board_Members_Direct_Input_LD102_2022: string;
  //non_male_Board_Members_Direct_Input_LD102_2023: string;
  //non_male_Executives_LD01_2022: string;
  //non_male_Executives_LD01_2023: string;
  //non_male_Executives_Direct_Input_LD101_2022: string;
  //non_male_Executives_Direct_Input_LD101_2023: string;
  //racially_Diverse_Board_Members_LD04_2022: string;
  //racially_Diverse_Board_Members_LD04_2023: string;
  //racially_Diverse_Executives_LD03_2022: string;
  //racially_Diverse_Executives_LD03_2023: string;
  //total_Board_Members_LD002_2022: string;
  //total_Board_Members_LD002_2023: string;
  //total_Executives_LD001_2022: string;
  //total_Executives_LD001_2023: string;
  //non_male_Boards_Ratio_2022: string;
  //non_male_Boards_Ratio_2023: string;
  //non_male_Boards_Score_2022: string;
  //non_male_Boards_Score_2023: string;
  //non_male_Executives_Ratio_2022: string;
  //non_male_Executives_Ratio_2023: string;
  //non_male_Executives_Score_2022: string;
  //non_male_Executives_Score_2023: string;
  //racially_Diverse_Among_Boards_Ratio_2022: string;
  //racially_Diverse_Among_Boards_Ratio_2023: string;
  //racially_Diverse_Among_Boards_Score_2022: string;
  //racially_Diverse_Among_Boards_Score_2023: string;
  //racially_Diverse_Among_Executives_Ratio_2022: string;
  //racially_Diverse_Among_Executives_Ratio_2023: string;
  //racially_Diverse_Among_Executives_Score_2022: string;
  //racially_Diverse_Among_Executives_Score_2023: string;
  //paid_Sick_Leave_Score_2022: string;
  //paid_Sick_Leave_Score_2023: string;
  //sustainability_Paylink_Score_2022: string;
  //sustainability_Paylink_Score_2023: string;
  //sustainability_Paylink_CEO_sustainable_compensation_SP04_2023: string;
  //sustainability_Paylink_CEO_total_compensation_SP05_2023: string;
  //sustainability_Paylink_Variable_Ratio_2023: string;
  //sustainability_Paylink_Variable_Ratio_Score_2023: string;
  //supplier_Score_2022: string;
  //supplier_Score_2023: string;
  //highest_Scoring_Supplier_ISIN_2023: string;
  //highest_Scoring_Supplier_Score_2023: string;
  //political_Influence_Score_2023: string;
  //fines_Sum_2023: string;
  //fines_Percentrank_2023: string;
  //energy_Weighted_Score_2023: string;
  //carbon_Weighted_Score_2023: string;
  //water_Weighted_Score_2023: string;
  //waste_Weighted_Score_2023: string;
  //noX_Weighted_Score_2023: string;
  //soX_Weighted_Score_2023: string;
  //voC_Weighted_Score_2023: string;
  //pM_Weighted_Score_2023: string;
  //cash_Taxes_Weighted_Score_2023: string;
  //ceO_Pay_Weighted_Score_2023: string;
  //pension_Fund_Quality_Weighted_Score_2023: string;
  //injury_Rate_Weighted_Score_2023: string;
  //fatalities_Weighted_Score_2023: string;
  //employee_Turnover_Weighted_Score_2023: string;
  //sick_Leave_Weighted_Score_2023: string;
  //non_Male_Boards_Weighted_Score_2023: string;
  //non_Male_Executives_Weighted_Score_2023: string;
  //racially_Diverse_Boards_Weighted_Score_2023: string;
  //racially_Diverse_Executives_Weighted_Score_2023: string;
  //sustainability_Paylink_Weighted_Score_2023: string;
  //sustainability_Paylink_Variable_Weighted_Score_2023: string;
  //political_Influence_1_Weighted_Score_2023: string;
  //political_Influence_2_Weighted_Score_2023: string;
  //political_Influence_3_Weighted_Score_2023: string;
  //sustainable_Revenue_Weighted_Score_2023: string;
  //sustainable_Investment_Weighted_Score_2023: string;
  //supplier_Weighted_Score_2023: string;
  //fines_Deduction: string;
  //overall_Score_2022: string;
  //overall_Score_2023: string;
  //access_to_Medicine: string;
  //access_to_Nutrition: string;
  //adult_Entertainment: string;
  //blocking_Climate_Policy: string;
  //cement_Carbon_Laggards: string;
  //civilian_Firearms: string;
  //controversial_Weapons: string;
  //conventional_Weapons: string;
  //deforestation_Palm_Oil_Laggards: string;
  //energy: string;
  //farm_Animal_Welfare: string;
  //profits_From_Private_Prisons: string;
  //forced_Labour_and_Genocide: string;
  //gambling: string;
  //gross_Corruption_Violation: string;
  //harmful_Pesticides: string;
  //illegal_Activity: string;
  //oil_Sands_Laggards: string;
  //severe_Environmental_Damage: string;
  //severe_Human_Rights_Violations: string;
  //thermal_Coal: string;
  //tobacco: string;
  //fossil_Fuel_Financing: string;
  //government_Sanctions: string;
  //blocking_Climate_Resolutions: string;
  //count_of_Flags: string;
}

//For data report - include values from controller

export interface ExportDataReport {
  permid: string;
  name: string;
  ckpG_Name: string;
  currency: string;
  country_of_headquarters: string;
  isin: string;
  instrument_is_active: string;
  ticker: string;
  adR_ticker: string;
  f_Score: string;
  company_Response_on_Portal: string;
  gicS_Industry_Group: string;
  gicS_Industry: string;
  gicS_Sector: string;
  global_100_last_year: string;
  purchasing_Power_Parity_Conversion_Rate: string;
  purchasing_Power_Parity_Revenue: string;
  energy_Productivity: string;
  energy_Productivity_Score: string;
  carbon_Productivity: string;
  carbon_Productivity_Score: string;
  water_Productivity: string;
  water_Productivity_Score: string;
  waste_Productivity: string;
  waste_Productivity_Score: string;
  voC_Productivity: string;
  voC_Productivity_Score: string;
  nOx_Productivity: string;
  nOx_Productivity_Score: string;
  sOx_Productivity: string;
  sOx_Productivity_Score: string;
  pM_Productivity: string;
  pM_Productivity_Score: string;
  cash_Taxes_Paid_Ratio: string;
  cash_Taxes_Paid_Score: string;
  clean_Revenue_Ratio: string;
  clean_Revenue_Score: string;
  clean_Investment_Ratio: string;
  clean_Investment_Score: string;
  ceO_Average_Worker_Pay_Ratio: string;
  ceO_Average_Worker_Pay_Score: string;
  retirement_Contributions_FTE: string;
  projected_Benefit_Obligation_FTE: string;
  fair_Value_Projected_Benefit_Obligation: string;
  pension_Fund_Quality: string;
  injury_Rate_Ratio: string;
  injury_Rate_Score: string;
  fatalities_Per_Employee_Ratio: string;
  fatalities_Per_Employee_Score: string;
  employee_Turnover_Ratio: string;
  employee_Turnover_Score: string;
  non_male_Boards_Ratio: string;
  non_male_Boards_Score: string;
  non_male_Executives_Ratio: string;
  non_male_Executives_Score: string;
  racially_Diverse_Among_Boards_Ratio: string;
  racially_Diverse_Among_Boards_Score: string;
  racially_Diverse_Among_Executives_Ratio: string;
  racially_Diverse_Among_Executives_Score: string;
  paid_Sick_Leave_Score: string;
  sustainability_Paylink_Score: string;
  sustainability_Paylink_Variable_Ratio: string;
  sustainability_Paylink_Variable_Ratio_Score: string;
  supplier_Score: string;
  political_Influence_Score: string;
  fines_Deduction: string;
  overall_Score: string;
  access_to_Medicine: string;
  access_to_Nutrition: string;
  adult_Entertainment: string;
  blocking_Climate_Policy: string;
  cement_Carbon_Laggards: string;
  civilian_Firearms: string;
  controversial_Weapons: string;
  conventional_Weapons: string;
  deforestation_Palm_Oil_Laggards: string;
  energy: string;
  farm_Animal_Welfare: string;
  profits_From_Private_Prisons: string;
  forced_Labour_and_Genocide: string;
  gambling: string;
  gross_Corruption_Violation: string;
  harmful_Pesticides: string;
  illegal_Activity: string;
  oil_Sands_Laggards: string;
  severe_Environmental_Damage: string;
  severe_Human_Rights_Violations: string;
  thermal_Coal: string;
  tobacco: string;
}


//For data report meta - include values from controller

export interface ExportDataReportMetadata {
  measurement: string;
  clean_Revenue_Ratio: string;
  clean_Revenue_Score: string;
  clean_Investment_Ratio: string;
  clean_Investment_Score: string;
  energy_Productivity: string;
  energy_Productivity_Score: string;
  carbon_Productivity: string;
  carbon_Productivity_Score: string;
  water_Productivity: string;
  water_Productivity_Score: string;
  waste_Productivity: string;
  waste_Productivity_Score: string;
  voC_Productivity: string;
  voC_Productivity_Score: string;
  noX_Productivity: string;
  noX_Productivity_Score: string;
  soX_Productivity: string;
  soX_Productivity_Score: string;
  pM_Productivity: string;
  pM_Productivity_Score: string;
  injury_Rate_Ratio: string;
  injury_Rate_Score: string;
  fatalities_Per_Employee_Ratio: string;
  fatalities_Per_Employee_score: string;
  employee_Turnover_Ratio: string;
  employee_turnover_score: string;
  paid_sick_leave_score: string;
  ceO_Average_Worker_Pay_Ratio: string;
  ceO_Average_Worker_Pay_score: string;
  non_male_Boards_Ratio: string;
  non_male_Boards_score: string;
  non_male_executives_Ratio: string;
  non_male_executives_score: string;
  racially_Diverse_Among_Boards_Ratio: string;
  racially_Diverse_Among_Boards_Score: string;
  racially_diverse_among_executives_Ratio: string;
  racially_diverse_among_executives_score: string;
  sustainability_paylink_score: string;
  supplier_score: string;
  cash_taxes_paid_ratio: string;
  cash_taxes_paid_score: string;
  retirement_Contributions_FTE: string;
  fair_Value_of_Plan_Assets_FTE: string;
  fair_Value_Projected_Benefit_Obligation: string;
  pension_Fund_Quality: string;
  overall_score: string;
  access_to_Medicine: string;
  access_to_Nutrition: string;
  adult_Entertainment: string;
  blocking_climate_policy: string;
  cement_carbon_laggards: string;
  civilian_firearms: string;
  controversial_weapons: string;
  conventional_Weapons: string;
  deforestation_Palm_oil_laggards: string;
  energy: string;
  farm_Animal_Welfare: string;
  profits_from_private_prisons: string;
  forced_labour_and_genocide: string;
  gambling: string;
  gross_corruption_violation: string;
  harmful_Pesticides: string;
  illegal_Activity: string;
  oil_sands_laggards: string;
  severe_environmental_damage: string;
  severe_human_rights_violations: string;
  thermal_Coal: string;
  tobacco: string;
}

//For scorecard input - include values from controller

export interface ExportScorecardInput {
  company_Name: string;
  ckpg: string;
  company_PERMID: string;
  gicS_Sector: string;
  gicS_Industry: string;
  country_Of_Headquarters: string;

  clean_Revenue_Ratio: string;
  clean_Revenue_Median: string;
  clean_Revenue_Score: string;

  clean_Investment_Ratio: string;
  clean_Investment_Median: string;
  clean_Investment_Score: string;

  energy_Productivity_Ratio: string;
  energy_Productivity_Median: string;
  energy_Productivity_Score: string;

  carbon_Productivity_Ratio: string;
  carbon_Productivity_Median: string;
  carbon_Productivity_Score: string;

  water_Productivity_Ratio: string;
  water_Productivity_Median: string;
  water_Productivity_Score: string;

  waste_Productivity_Ratio: string;
  waste_Productivity_Median: string;
  waste_Productivity_Score: string;

  voC_Productivity_Ratio: string;
  voC_Productivity_Median: string;
  voC_Productivity_Score: string;

  noX_Productivity_Ratio: string;
  noX_Productivity_Median: string;
  noX_Productivity_Score: string;

  soX_Productivity_Ratio: string;
  soX_Productivity_Median: string;
  soX_Productivity_Score: string;

  pM_Productivity_Ratio: string;
  pM_Productivity_Median: string;
  pM_Productivity_Score: string;

  lost_Time_Injury_Rate: string;
  lost_Time_Injury_Rate_Median: string;
  lost_Time_Injury_Rate_Score: string;

  number_of_Fatalities_Ratio: string;
  number_of_Fatalities_Median: string;
  number_of_Fatalities_score: string;

  employee_Turnover_Ratio: string;
  employee_Turnover_Median: string;
  employee_turnover_score: string;

  paid_sick_leave_score: string;
  ceO_Worker_Pay_Ratio: string;
  ceO_Worker_Pay_Ratio_Median: string;
  ceO_Worker_Pay_score: string;

  non_male_Boards: string;
  non_male_Boards_Median: string;
  non_male_Boards_score: string;

  non_male_executives: string;
  non_male_executives_Median: string;
  non_male_executives_score: string;

  racially_Diverse_Boards_Ratio: string;
  racially_Diverse_Boards_Median: string;
  racially_Diverse_Boards_Score: string;

  racially_diverse_executives_Ratio: string;
  racially_diverse_executives_Median: string;
  racially_diverse_executives_score: string;

  sustainability_paylink_score: string;
  supplier_score: string;
  cash_taxes_paid_ratio: string;
  cash_taxes_paid_Median: string;
  cash_taxes_paid_score: string;

  retirement_Contributions: string;
  retirement_Contributions_Median: string;
  retirement_Contributions_Score: string;

  fair_Value_of_Plan_Assets: string;
  fair_Value_of_Plan_Assets_Median: string;
  fair_Value_of_Plan_Assets_Score: string;

  defined_benefit_pension_funded_status: string;
  defined_benefit_pension_funded_status_median: string;
  defined_benefit_pension_funded_status_score: string;
  overall_score: string;
}


export interface ExportCompanyKPI {
  companyName: string;
  permid: string;
  industryGroup: string;
  country: string;
  currency: string;
  kpiCode: string;
  kpiNames: string;
  kpiValue: string;
  scope: string;
  scopeValue: string;
  url: string;
  notes: string;
  fiscalYear: string;
  status: string;
  isActive: string;
  revenueFY: string;
  dateLastUpdated: string;
}

export interface ExportCompanies {
  permid: string;
  name: string;
  industry: string;
  country: string;
  currency: string;
  //isActive: string;
  //LatestFYEarnings: string;
}

export interface ExportKPIs {
  category: string;
  subcategory: string;
  name: string;
  description: string;
  kpiCode: string;
}

export interface ImportCompanies {
  companyName: string;
  permid: string;
  symbol: string;
  industryGroup: string;
  country: string;
  currency: string;
}

export interface ImportKPIs {
  permid: string;
  kpiCode: string;
  fiscalyear: string;
  value: string;
  reference: string;
  source: string;
  scope: string;
  guidance: string;
  capex_type: string;
  zero_type: string;
  annual_or_Cumulative: string;
  environmental_or_Social: string;
  proxy: string;
  commitment: string;
  years_of_commitment: string;
  ck_notes: string;
  company_notes: string;
  analyst: string;
  qa_analyst: string;
  //isConfidential: boolean;
  company_Correspondence: boolean;
  research_Date: string;
  qa_date: string;

}

export interface ImportFlags {
  permid: string;
  ISIN: string;
  value: string;
  name: string;
  kpiCode: string;
  fiscalyear: string;

}

export interface ImportUsers {
  email: string;
  firstName: string;
  lastName: string;
  permid: string;
  userRole: string;
  title: string;
  titleCategorization: string;
  source: string;
  dateCreated: string;
  respondedTo: string;
}

export interface MsgItem {
  message: string;
}
