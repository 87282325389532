import { Component, OnInit } from '@angular/core';
import { UserService, User, UserSaved, EmailSent } from '../../../services/user.service';
import { faTools, faDatabase, faMailBulk, faFileContract, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ListValuesService, ExportCompanyKPI, ListValue, ExportCompanies, ExportUsers, ExportDataReport, ExportDataReportMetadata, ExportScorecardInput, ExportQAOutput, SyncDatabases, MsgItem, ExportSEISummary1, ExportSEISummary2, ExportSEIDeltas, ExportKPIs, ExportSEIQuickReport } from '../../../services/listvalues.service';
import { error } from 'protractor';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.css']
})
export class AdminMainComponent implements OnInit {
  adminIcon = faTools;
  dbIcon = faDatabase;
  emailIcon = faMailBulk;
  siteContentIcon = faFileContract;
  activityIcon = faCalendar;
  public exportSEIQuickReport: ExportSEIQuickReport[];

  eStatus: EmailSent;

  statusMessageKPI: string = '';
  statusMessageCo: string = '';
  statusMessageUsr: string = '';
  startTime: any;
  endTime: any;
  csv: string = '';

  constructor(private userService: UserService, private listValuesService: ListValuesService) { }

  ngOnInit() {
  }

  //For exporting SEI Quick Report - Update columns so they match the stored proc, update header line

  getSEIQuickReport() {
    this.startTime = new Date();
    this.statusMessageUsr = 'Loading data...';

    // Get values

    this.listValuesService.exportSEIQuickReport().subscribe(result => {
      this.exportSEIQuickReport = result;

      // Header line
      this.csv = `Rating year,Number of reviewed companies (Sustainable Revenue),Number of companies with non-zero Sustainable Revenue,Total Sustainable Revenue (PPP Billions),Gross revenue (PPP Billions),Number of reviewed companies (Sustainable Investment),Number of companies with non-zero Sustainable Investment,Total Sustainable Investment (PPP Billions),Total investment (PPP Billions)\n`;
      // Build File

      debugger;

      var i;
      for (i = 0; i < this.exportSEIQuickReport.length; i++) {
        this.csv = this.csv + '"' +
          // Fill in CSV columns after defining in listvalues service
          this.exportSEIQuickReport[i].rating_Year + '","' +
          this.exportSEIQuickReport[i].number_Reviewed_Companies_SR + '","' +
          this.exportSEIQuickReport[i].number_Companies_non_zero_SR + '","' +
          this.exportSEIQuickReport[i].total_SR_PPP_Billions + '","' +
          this.exportSEIQuickReport[i].gross_Revenue_PPP_Billions + '","' +
          this.exportSEIQuickReport[i].number_Reviewed_Companies_SI + '","' +
          this.exportSEIQuickReport[i].number_Companies_non_zero_SI + '","' +
          this.exportSEIQuickReport[i].total_SI_PPP_Billions + '","' +
          this.exportSEIQuickReport[i].total_Investment_PPP_Billions + '","' + '"\n';
      }

      debugger;
      this.endTime = new Date();
      var timeDiff = this.endTime - this.startTime; //in ms
      // strip the ms
      timeDiff /= 1000;
      // get seconds 
      var seconds = Math.round(timeDiff);
      this.statusMessageUsr = 'Found ' + this.exportSEIQuickReport.length.toString() + ' entries in ' + seconds + ' seconds. Saving to file...';

      this.downloadFile('SEI Quick Report', this.csv);
    }, error => console.error(error));
  }

  downloadFile(params: string, data: any) {
    //const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    //const header = Object.keys(data[0]);
    //let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    //csv.unshift(header.join(','));
    //let csvArray = csv.join('\r\n');

    var blob = new Blob([data], { type: 'text/csv' })
    var today = new Date();
    var sDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    saveAs(blob, params + " - " + sDate + ".csv");
  }

  sendAllWelcomeEmail() {
    if (confirm('Are you sure you want to send this email to All First Time Users?')) {
      // Yes
      this.userService.sendAllWelcomeEmail('3').subscribe(result => {
        this.eStatus = result;
        // Status message
        alert('Emails sent: ' + this.eStatus.message);
      }, error => {
        console.error(error);
        alert('Emails not sent: ' + error);
      })
    }
  }
}
