import { Component } from '@angular/core';
import { UserService, User, UserSaved } from '../../services/user.service';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { map, tap } from 'rxjs/operators';
import { faHome, faBuilding, faAsterisk, faUser, faTools, faChartBar, faHandsHelping, faFileExport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public user: User;

  public isAuth: boolean = false;
  public isCorp: boolean = false;
  public isDataAccess: boolean = false;
  public isReadOnly: boolean = false;
  public isAdmin: boolean = true;
  public companyId: number;

  public companyType: string = 'Company';

  isExpanded = false;

  homeIcon = faHome;
  corpIcon = faBuilding;
  irIcon = faAsterisk;
  userIcon = faUser;
  adminIcon = faTools;
  kpiIcon = faChartBar;
  feedbackIcon = faHandsHelping;
  exportIcon = faFileExport;

  feedbackMsg: string;

  constructor(private userService: UserService,
    private authorizeService: AuthorizeService) {

    this.user = {} as User;

    // Retrieve the current user info
    // Check if logged in page
    //if (document.location.href.indexOf('/authentication/login', 0) < 0) {
      // If so, check if user IsCorp or not - by the role
      userService.getCurrentUser().subscribe(result => {
        this.user = result;
        this.companyId = this.user.companyId;
        this.isDataAccess = (this.user.dataServicesAccess == 1);
        if (this.user.userRole.indexOf('CK ', 0) == -1) {
          this.isCorp = true;
          if (this.user.companyType == 'MBA') {
            this.companyType = 'School';
          }
          if (this.user.companyType == 'Sustainable Cities Index') {
            this.companyType = 'City';
          }
        }
        if (this.user.userRoleId == 1)
          this.isAdmin = false;

        this.isReadOnly = this.user.readOnly;
        this.isAuth = true;

        // Check if authenticated using AuthorizeService
        this.isAuthenticated = this.authorizeService.isAuthenticated();
        // Grab username
        this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
        this.feedbackMsg = 'research@corporateknights.com?Subject=Feedback%20from%20' + this.user.firstName + '%20' + this.user.lastName + '&Body=Page:%20%0A' + document.location.href + '%0A%0AEnter%20your%20feedback%20here%3A%0A%0A';

      }, error => {
        //console.error(error);
        // on error - go to login
        //document.location.href = '/authentication/login/';
      });
    //}
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
