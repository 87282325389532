import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CompanyService, Company } from '../../../services/company.service';
import { UserService, User, ActivityInfo } from '../../../services/user.service';
import { ListValuesService, ListValue } from '../../../services/listvalues.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { of, scheduled } from 'rxjs';
import { faBuilding, faToggleOn } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-switch',
  templateUrl: './admin-switch.component.html',
  styleUrls: ['./admin-switch.component.css']
})
export class AdminSwitchComponent implements OnInit {
  public isReadOnly: boolean;

  adminDetails = new FormGroup({});
  isActive = new FormControl(true);

  switchIcon = faToggleOn;

  constructor(
    private pickListService: ListValuesService,
    private formBuilder: FormBuilder,
    private router: Router,
    route: ActivatedRoute) {

    // Retrieve the item
    this.pickListService.GetReadOnly().subscribe(result => {
      this.isReadOnly = result;
      this.isActive.setValue(this.isReadOnly);
    }, error => console.error(error));
  }

  ngOnInit() {
  }

  saveSwitch() {
    var sChanges = '';
    pickListService: ListValuesService
    var saveValue = '0';
    if (this.isActive.value == true)
      saveValue = "1";

    this.pickListService.SetReadOnly(saveValue).subscribe(result => {
      //this.company = result[0];
      this.router.navigate(['/admin/admin-main']);

    }, error => console.error(error));
  }
}

