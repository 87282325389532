import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteContentService {
  http: HttpClient;
  baseUrl: string;
  company: Observable<SiteContent[]>;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getContents(): Observable<SiteContent[]> {
    return this.http.get<SiteContent[]>(this.baseUrl + 'api/sitecontent');
  }

  getContent(id): Observable<SiteContent[]> {
    return this.http.get<SiteContent[]>(this.baseUrl + 'api/sitecontent/' + id);
  }

  setContent(body: SiteContent, id: number): Observable<any> {
    return this.http.put(this.baseUrl + 'api/sitecontent/savesitecontent/' + id, body);
  }

  deleteContent(id) {
    return this.http.delete(this.baseUrl + 'api/sitecontent/deletesitecontent/' + id);
  }
}

export interface SiteContent {
  id: number;
  name: string;
  title: string;
  body: string;
}
