import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public loggedIn: boolean;

  constructor(private authorizeService: AuthorizeService) {
  }

  ngOnInit() {
    this.loggedIn = false;
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));

    this.authorizeService.getUser()
      .subscribe(data => {
        //debugger;
        if (data == null) {
          if (document.location.href.indexOf('/authentication/login', 0) < 0) {
            document.location.href = '/authentication/login/';
          }
          //console.log(data);
        }
      });
  }
}
