import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { AdminMainComponent } from './admin/admin-main/admin-main.component';
import { AdminSwitchComponent } from './admin/admin-switch/admin-switch.component';
import { PrivacyComponent } from './info/privacy/privacy.component';
import { FeedbackComponent } from './info/feedback/feedback.component';
import { AppComponent } from './app.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TestComponent } from './test/test/test.component';
import { DatePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TestComponent,
    NotFoundComponent,
    AdminMainComponent,
    AdminSwitchComponent,
    PrivacyComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ApiAuthorizationModule,
    NgbModule,
    FontAwesomeModule,
    AgGridModule.withComponents([]),
    
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'not-found', component: NotFoundComponent },
      { path: 'admin/admin-main', component: AdminMainComponent },
      { path: 'admin/admin-switch', component: AdminSwitchComponent },
      { path: 'info/privacy', component: PrivacyComponent },
      { path: 'info/feedback', component: FeedbackComponent },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'data',
        loadChildren: () => import('./data/data.module').then(m => m.DataModule)
      },
      {
        path: 'industrygroups',
        loadChildren: () => import('./industrygroups/industrygroups.module').then(m => m.IndustryGroupsModule)
      },
      {
        path: 'kpi',
        loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule)
      }, {
        path: 'emailtemplates',
        loadChildren: () => import('./email/email.module').then(m => m.EmailListModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('./company/ckcompany.module').then(m => m.CkcompanyModule)
      },
      {
        path: 'surveys',
        loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule)
      },
      {
        path: 'sitecontent',
        loadChildren: () => import('./sitecontent/sitecontent.module').then(m => m.ContentModule)
      },
      {
        path: 'informationrequests',
        loadChildren: () => import('./ckinformationrequest/Ckinformationrequest.module').then(m => m.CkInformationRequestModule)
      }
    ], { enableTracing: false, anchorScrolling: 'enabled' }),
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
