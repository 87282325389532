import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityLog, ListValue } from './listvalues.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  http: HttpClient;
  baseUrl: string;
  company: Observable<Company[]>;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(this.baseUrl + 'api/ckcompany');
  }

  getActivity(limit: number): Observable<Company[]> {
    return this.http.get<Company[]>(this.baseUrl + 'api/ckcompany/getactivity/' + limit.toString());
  }

  getCompany(id): Observable<CompanySave> {
    return this.http.get<CompanySave>(this.baseUrl + 'api/ckcompany/'+ id);
  }

  setCompany(body: CompanySave, id: number): Observable<any> {
    return this.http.put(this.baseUrl + 'api/ckcompany/savecompany/' + id, body);
  }

  deleteCompany(id) {
    return this.http.delete(this.baseUrl + 'api/ckcompany/deletecompany/' + id);
  }
}

export interface ListSupplierValue {
  text_id: string;
  text_name: string;
}

export interface Company {
  companyId: number;
  name: string;
  permid: string;
  tickerSymbol: string;
  country: string;
  industryGroupId: number;
  isActive: number;
  reportingCurrency: string;
  currentFiscalKPIStatus: string;
  countryCurrencyDescription: string;
  numberAccessed: number;
  dateSubmitted: string;
  lastLogin: string;
  recentlyModifiedMessage: string;
  eligibleRankings: string;
  isPublic: number;
  grossRevenueUSD: string;
  isin: string;
  otherSuppliers: string;
  isSubmitted: number;
  dateCreated: string;
  industryGroupDisp: string;
  isinList: string;
  isinCount: number;
  dataServicesAccess: number;
  dataServicesTrial: number;
}

export interface CompanySave {
  companyId: number;
  name: string;
  permid: string;
  tickerSymbol: string;
  country: string;
  industryGroupId: number;
  isActive: number;
  reportingCurrency: string;
  currentFiscalKPIStatus: string;
  countryCurrencyDescription: string;
  numberAccessed: number;
  dateSubmitted: string;
  lastLogin: string;
  recentlyModifiedMessage: string;
  supplierList: ListSupplierValue[];
  eligibleRankings: string;
  isPublic: number;
  grossRevenueUSD: string;
  isin: string;
  otherSuppliers: string;
  isSubmitted: boolean;
  isinList: string;
  isinCount: number;
  dataServicesAccess: number;
  dataServicesTrial: number;
}

