import { Component } from '@angular/core';
import { UserService, User, UserSaved } from '../../services/user.service';
import { SiteContentService, SiteContent } from '../../services/sitecontent.service';
import { Company, CompanySave, CompanyService } from '../../services/company.service';
import { KPIService, MsgItem } from '../../services/kpi.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  public siteContent: SiteContent;
  public user: User;
  public isAuth: boolean;
  public company: CompanySave;
  public stats: MsgItem = ({ message: '' });

  contentTitle: string = '';
  contentBody: string = '';
  modifiedMessage: string = '';
  companyID: number = 0;

  constructor(private userService: UserService,
    private companyService: CompanyService,
    private kpiService: KPIService,
    private siteContentService: SiteContentService) {

    this.isAuth = false;

    this.user = {} as User;

    // Retrieve the current user info
    // Check if logged in page
    // If so, check if user IsCorp or not - by the role
    userService.getCurrentUser().subscribe(result => {
      debugger;
      this.user = result;
      this.isAuth = true;
      this.companyID = this.user.companyId;
      this.companyService.getCompany(this.user.companyId).subscribe(result => {
        debugger;
        this.company = result[0];
        //if (this.company.recentlyModifiedMessage != null) {
        //  this.modifiedMessage = this.company.recentlyModifiedMessage;
        //}


      }, error => console.error(error));

      siteContentService.getContent(1).subscribe(result => {
        debugger;
        this.siteContent = result[0];
        if (!this.user.readOnly) {
          this.contentTitle = this.siteContent.title;
          this.contentBody = this.siteContent.body;
        }
        else {
          this.contentTitle = 'Notice';
          this.contentBody = 'The Corporate Knights Data Portal is undergoing monthly maintenance, and will be locked to external users until next week.';
        }
      }, error => console.error(error));
    }, error => {
      //console.error(error);
    });

    kpiService.checkActivity().subscribe(result => {
      this.stats = result;
    }, error => console.error(error));
  }
}
