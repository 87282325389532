import { Component, OnInit } from '@angular/core';
import { UserService, User, UserSaved } from '../../../services/user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  public user: User;
  feedbackMsg: string;
  MBAfeedbackMsg: string;

  constructor(private userService: UserService) {
    this.user = {} as User;

    // Retrieve the current user info
    // Check if logged in page
    //if (document.location.href.indexOf('/authentication/login', 0) < 0) {
    // If so, check if user IsCorp or not - by the role
    this.userService.getCurrentUser().subscribe(result => {
      this.user = result;

      //this.feedbackMsg = 'research@corporateknights.com?Subject=Feedback%20from%20' + this.user.firstName + '%20' + this.user.lastName + '&Body=Page:%20%0A' + document.location.href + '%0A%0AEnter%20your%20feedback%20here%3A%0A%0A';
      this.feedbackMsg = 'research@corporateknights.com?Subject=Feedback%20from%20' + this.user.firstName + '%20' + this.user.lastName + '&Body=Enter%20your%20feedback%20here%3A%0A%0A';
      this.MBAfeedbackMsg = 'betterworldMBA@corporateknights.com?Subject=Feedback%20from%20' + this.user.firstName + '%20' + this.user.lastName + '&Body=Enter%20your%20feedback%20here%3A%0A%0A';
    }, error => {
      //console.error(error);
      // on error - go to login
      //document.location.href = '/authentication/login/';
    });
  }
  
  ngOnInit() {
    
  }

}
