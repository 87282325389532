import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KPIService {
  http: HttpClient;
  baseUrl: string;
  listValues: KPIValue[];

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getCompanyKPIs(id, categoryFilter: string): Observable<KPIValue[]> {
    return this.http.get<KPIValue[]>(this.baseUrl + 'api/kpi/getcompanykpis/' + id + '/' + categoryFilter);
  }

  setKPI(body: KPIValue[], id: string): Observable<any> {
    return this.http.put(this.baseUrl + 'api/kpi/savecompanykpis/' + id, body);
  }

  deleteKPIRecord(kpiID: number, companyID: number) {
    var values = kpiID + ':' + companyID;
   return this.http.delete(this.baseUrl + 'api/kpi/deletekpirecord/' + values);
  }

  confirmAll(id: string): Observable<MsgItem> {
    return this.http.get<MsgItem>(this.baseUrl + 'api/kpi/confirmcompanykpis/' + id);
  }

  getKPI(id): Observable<KPIItem[]> {
    return this.http.get<KPIItem[]>(this.baseUrl + 'api/kpi/get/' + id);
  }

  getKPISustainable(): Observable<KPIItem[]> {
    return this.http.get<KPIItem[]>(this.baseUrl + 'api/kpi/getsustainable');
  }

  getKPIFire(): Observable<KPIItem[]> {
    return this.http.get<KPIItem[]>(this.baseUrl + 'api/kpi/getfire');
  }

  checkActivity(): Observable<MsgItem> {
    return this.http.get<MsgItem>(this.baseUrl + 'api/kpi/checkactivity');
  }

  clearModified(companyID: string): Observable<MsgItem> {
    
    return this.http.get<MsgItem>(this.baseUrl + 'api/kpi/clearmodified/' + companyID);
  }


  updateKPI(body: KPIItem, id: string): Observable<any> {
    return this.http.put(this.baseUrl + 'api/kpi/update/' + id, body);
  }

  deleteKPI(id) {
    this.http.delete(this.baseUrl + 'api/kpi/deletekpi/' + id).subscribe(
      result => console.log(result),
      err => console.error(err)
    );;
  }



}

export interface KPIItem {
  kpiid: number;
  name: string;
  description: string;
  category: string;
  subCategory: string;
  source: string;
  isActive: number;
  scopeDescription: string;
  units: string;
  kpitype: string;
  choices: string;
  sequence: number;
  displaySize: number;
  kpiCode: string;
}

export interface KPIValue {
  id: number;
  companyId: number;
  fiscalYear: number;
  kpiCompanyMapID: number;
  sequence: number;
  displaySize: number;
  name: string;
  description: string;
  kpiCategory: string;
  kpiSubcategory: string;
  displayMode: string;
  type: string;
  choices: string;
  modifiedDate: string;
  units: string;
  kpiCode: string;

  fY1Value: string;
  fY1Status: string;
  fY2Value: string;
  fY2Status: string;
  fY3Value: string;
  fY3Status: string;
  fY4Value: string;
  fY4Status: string;
  fY5Value: string;
  fY5Status: string;

  reference1: string;
  source1: string;
  company_Notes1: string;
  capex_Type1: string;
  zero_Type1: string;
  annual_or_Cumulative1: string;
  environmental_or_Social1: string;
  cK_Notes1: string;
  proxy1: string;
  analyst1: string;
  qA_Analyst1: string;
  commitment1: string;
  years_of_Commitment1: string;
  scope1: number;
  guidance1: string;
  revenueSegment1: number;
  isConfidential1: boolean;
  company_Correspondence1: boolean;
  research_Date1: string;
  qA_Date1: string;

  reference2: string;
  source2: string;
  company_Notes2: string;
  capex_Type2: string;
  zero_Type2: string;
  annual_or_Cumulative2: string;
  environmental_or_Social2: string;
  cK_Notes2: string;
  proxy2: string;
  analyst2: string;
  qA_Analyst2: string;
  commitment2: string;
  years_of_Commitment2: string;
  scope2: number;
  guidance2: string;
  revenueSegment2: number;
  isConfidential2: boolean;
  company_Correspondence2: boolean;
  research_Date2: string;
  qA_Date2: string;

  reference3: string;
  source3: string;
  company_Notes3: string;
  capex_Type3: string;
  zero_Type3: string;
  annual_or_Cumulative3: string;
  environmental_or_Social3: string;
  cK_Notes3: string;
  proxy3: string;
  analyst3: string;
  qA_Analyst3: string;
  commitment3: string;
  years_of_Commitment3: string;
  scope3: number;
  guidance3: string;
  revenueSegment3: number;
  isConfidential3: boolean;
  company_Correspondence3: boolean;
  research_Date3: string;
  qA_Date3: string;

  reference4: string;
  source4: string;
  company_Notes4: string;
  capex_Type4: string;
  zero_Type4: string;
  annual_or_Cumulative4: string;
  environmental_or_Social4: string;
  cK_Notes4: string;
  proxy4: string;
  analyst4: string;
  qA_Analyst4: string;
  commitment4: string;
  years_of_Commitment4: string;
  scope4: number;
  guidance4: string;
  revenueSegment4: number;
  isConfidential4: boolean;
  company_Correspondence4: boolean;
  research_Date4: string;
  qA_Date4: string;

  reference5: string;
  source5: string;
  company_Notes5: string;
  capex_Type5: string;
  zero_Type5: string;
  annual_or_Cumulative5: string;
  environmental_or_Social5: string;
  cK_Notes5: string;
  proxy5: string;
  analyst5: string;
  qA_Analyst5: string;
  commitment5: string;
  years_of_Commitment5: string;
  scope5: number;
  guidance5: string;
  revenueSegment5: number;
  isConfidential5: boolean;
  company_Correspondence5: boolean;
  research_Date5: string;
  qA_Date5: string;

  isDirty: string;
  ratioValue: string;
  categoryShow: boolean;
}

export interface MsgItem {
  message: string;
}
