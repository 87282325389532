import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityLog, MultiselectListValue } from './listvalues.service';
import { debug } from 'console';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  http: HttpClient;
  baseUrl: string;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/users/');
  }

  getCompanyUsers(companyId: string): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/users/companyusers/' + companyId);
  }

  getActivity(limit: number): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/users/getactivity/' + limit.toString());
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/users/' + id);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/users/0');
  }

  setUser(body: User, id: string): Observable<any> {
    return this.http.put(this.baseUrl + `api/users/saveuser/${id}`, body);
  }

  deleteUser(id): Observable<User[]> {
    return this.http.delete<User[]>(this.baseUrl + 'api/users/deleteuser/' + id);
  }

  resetUser(body: User, id: string): Observable<any> {
    return this.http.put(this.baseUrl + `api/users/resetuser/${id}`, body);
  }

  sendEmail(id, type): Observable<any> {
    return this.http.get(this.baseUrl + 'api/users/sendEmail/' + id + ':' + type);
  }

  sendApproval(body: KPIValue[], email: string): Observable<any> {
    return this.http.put(this.baseUrl + 'api/users/sendApproval/' + email, body);
  }

  sendAllWelcomeEmail(type: string): Observable<any> {
    return this.http.get(this.baseUrl + 'api/users/sendAllWelcomeEmail/' + type);
  }

  logActivty(body: ActivityInfo): Observable<any> {
    return this.http.put(this.baseUrl + 'api/users/logactivity/', body);
  }
}



export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: number;
  companyId: number;
  companyName: string;
  permid: string;
  userRole: string;
  userRoleId: number;
  welcomeSent: number;
  title: string;
  titleCategorization: string;
  source: string;
  dateCreated: string;
  respondedTo: string;
  lastLogin: string;
  lastLoginDate: string;
  kpiList: MultiselectListValue[];
  readOnly: boolean;
  receivesChangeNotifications: number;
  receivesEmails: number;
  dataServicesAccess: number;
  dataServicesTrial: number;
  companyType: string;
  isinList: string;
  isinCount: number;
}

export interface UserSaved {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: number;
  companyId: number;
  companyName: string;
  permid: string;
  userRole: string;
  userRoleId: number;
  welcomeSent: number;
  title: string;
  titleCategorization: string;
  source: string;
  dateCreated: string;
  respondedTo: string;
  lastLogin: string;
  lastLoginDate: string;
  kpiList: MultiselectListValue[];
  readOnly: boolean;
  receivesChangeNotifications: number;
  receivesEmails: number;
  dataServicesAccess: number;
  dataServicesTrial: number;
  companyType: string;
  isinList: string;
  isinCount: number;
}

export interface EmailSent {
  message: string;
  count: number;
}

export interface KPIValue {
  id: number;
  companyId: number;
  fiscalYear: number;
  kpiCompanyMapID: number;
  sequence: number;
  displaySize: number;
  name: string;
  description: string;
  kpiCategory: string;
  type: string;
  units: string;
  fY1Value: string;
  fY1Status: string;
  fY2Value: string;
  fY2Status: string;
  fY3Value: string;
  fY3Status: string;
  fY4Value: string;
  fY4Status: string;
  fY5Value: string;
  fY5Status: string;
  source1: string;
  reference1: string;
  scope1: number;
  guidance1: string;
  revenueSegment1: number;
  source2: string;
  reference2: string;
  scope2: number;
  guidance2: string;
  revenueSegment2: number;
  source3: string;
  reference3: string;
  scope3: number;
  guidance3: string;
  revenueSegment3: number;
  source4: string;
  reference4: string;
  scope4: number;
  guidance4: string;
  revenueSegment4: number;
  source5: string;
  reference5: string;
  scope5: number;
  guidance5: string;
  revenueSegment5: number;
  isDirty: string;
  ratioValue: string;
  categoryShow: boolean;
}

export interface ActivityInfo {
  email: string;
  action: string
  notes: string
  status: string
}
